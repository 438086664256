import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { FiltrosSam } from '../../components/skyapplicantmanagement';
import { useApplicant } from '../../hooks';
import { DroppableSam, DraggableSam } from '../../components/skyapplicantmanagement';

export function KanbanApplicantReportPage() {
    const toast = useRef(null);

    const { getReportApplicant, listApplicants, loadingApplicant } = useApplicant();

    const list_filtros = {
        FechaAplicacion: true,
        EstadoAplicante: true,
        Pauta: true,
        Idioma: true,
        NivelIdioma: true,
        Pais: true,
        Nombre: true,
        Cuenta: false,
        Job: true,
        FechaAprobacion: false,
        Referido: true
    };

    const list_required = {
        FechaAplicacion: true,
        EstadoAplicante: true
    };

    useEffect(() => {
        if (toast.current) {
            toast.current.show({
                severity: 'info',
                summary: 'Cambio de Estado',
                detail: 'Se cambió el estado del aplicante a nada',
            });
        }
    }, []);

    //console.log(listApplicants);

    const onResponseForm = async (FormData) => {
        await getReportApplicant(moment(FormData.FechaAplicacion[0]).format('YYYY-MM-DD'), moment(FormData.FechaAplicacion[1]).format('YYYY-MM-DD'), FormData.EstadoAplicante, FormData);
    };

    const [items] = useState(['1', '2', '3', '4', '5']);
    const [activeId, setActiveId] = useState(null);
    const [itemDropZone, setItemDropZone] = useState([]);
    const [itemDropZoneLead, setItemDropZoneLead] = useState([]);

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Filtros Aplicantes</h5>
                        <p>Selecciona todos los filtros a aplicar dentro del panel kanban</p>

                        <FiltrosSam filtros={list_filtros} response={onResponseForm} param_required={list_required} />
                    </div>
                </div>
            </div>

            <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                <div className="grid">
                    {items.map((id) => (
                        <>
                            {itemDropZone.includes(id) || itemDropZoneLead.includes(id) ? (
                                <></>
                            ) : (
                                
                                    <div className="col-2" key={id*100}>
                                        <DraggableSam key={id} id={id}>
                                            Drag {id}
                                        </DraggableSam>
                                    </div>
                                
                            )}
                        </>
                    ))}
                    <DragOverlay>{activeId ? <button>overlay Drag {activeId}</button> : null}</DragOverlay>
                </div>

                <div className="grid">
                    <div className="col-2">
                        <DroppableSam items={itemDropZone} idDrop="PreLead" AcceptDrop={['type1', 'type2']} tituloDrop="Aplicantes en PreLead" />
                    </div>
                    <div className="col-2">
                        <DroppableSam items={itemDropZoneLead} idDrop="Rechazados" AcceptDrop={['type1', 'type2']} tituloDrop="Aplicantes en Rechazados" />
                    </div>
                    <div className="col-2">
                        <DroppableSam items={itemDropZoneLead} idDrop="Lead" AcceptDrop={['type1', 'type2']} tituloDrop="Aplicantes en Lead" />
                    </div>
                    <div className="col-2">
                        <DroppableSam items={itemDropZoneLead} idDrop="Entrevista Con Supervisor" AcceptDrop={['type1', 'type2']} tituloDrop="Aplicantes en Entrevista con Supervisor" />
                    </div>
                    <div className="col-2">
                        <DroppableSam items={itemDropZoneLead} idDrop="Entrevista Finales" AcceptDrop={['type1', 'type2']} tituloDrop="Aplicantes en Entrevista Finales" />
                    </div>
                    <div className="col-1">
                        <DroppableSam items={itemDropZoneLead} idDrop="Documentacion" AcceptDrop={['type1', 'type2']} tituloDrop="Aplicantes en Documentacion" />
                    </div>
                    <div className="col-1">
                        <DroppableSam items={itemDropZoneLead} idDrop="Pipepline" AcceptDrop={['type1', 'type2']} tituloDrop="Aplicantes en Pipepline" />
                    </div>
                </div>
            </DndContext>
        </>
    );

    function handleDragStart(event) {
        setActiveId(event.active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;

        if (over && over.data.current.accepts.includes(active.data.current.type)) {
            if (over.id == 'Lead') {
                setItemDropZoneLead((prev) => [...prev, active.id]);
                setItemDropZone((prev) => prev.filter((item) => item !== active.id));
            } else {
                setItemDropZone((prev) => [...prev, active.id]);
                setItemDropZoneLead((prev) => prev.filter((item) => item !== active.id));
            }
            /* if (!itemDropZone.includes(active.id)) {
            } */
            
            toast.current.show({ severity: 'info', summary: 'Cambio de Estado', detail: `Se cambio el estado del aplicante a ${over.id}` });
        } else {
            if (over.id == 'Lead') {
                setItemDropZoneLead((prev) => prev.filter((item) => item !== active.id));
            } else {
                setItemDropZone((prev) => prev.filter((item) => item !== active.id));
            }
        }

        setActiveId(null);
    }
}
