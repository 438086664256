import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { Card } from 'primereact/card';

export function DraggableSam(props) {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: props.id,
        data: {
            type: 'type2'
        }
    });

    /* const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined; */

    return (
        <Card id={props.id} ref={setNodeRef} {...listeners} {...attributes} className='my-3'>
            {props.children}
        </Card>
    );
}
