import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { DraggableSam } from './DraggableSam';

export function DroppableSam({ children, items, idDrop, AcceptDrop, tituloDrop, descripcionDrop }) {
    const { isOver, setNodeRef } = useDroppable({
        id: idDrop,
        data: {
            accepts: AcceptDrop
        }
    });

    return (
        <div ref={setNodeRef} style={{ borderStyle: 'solid', borderColor: isOver ? 'green' : 'red' }}>
            <div className="grid p-3">
                <div className="col-12">
                    <h5>{tituloDrop}</h5>
                    <p>{descripcionDrop}</p>
                    {items.map((item) => (
                        <DraggableSam key={item} id={item}>
                            {' '}
                            Drag {item}{' '}
                        </DraggableSam>
                    ))}
                </div>
            </div>
        </div>
    );
}
